// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certificacion-js": () => import("./../../../src/pages/certificacion.js" /* webpackChunkName: "component---src-pages-certificacion-js" */),
  "component---src-pages-contenidos-js": () => import("./../../../src/pages/contenidos.js" /* webpackChunkName: "component---src-pages-contenidos-js" */),
  "component---src-pages-creditos-js": () => import("./../../../src/pages/creditos.js" /* webpackChunkName: "component---src-pages-creditos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-js": () => import("./../../../src/pages/informacion.js" /* webpackChunkName: "component---src-pages-informacion-js" */),
  "component---src-pages-referencias-js": () => import("./../../../src/pages/referencias.js" /* webpackChunkName: "component---src-pages-referencias-js" */)
}

